import { Cached, Save } from '@mui/icons-material'
import React, { useEffect } from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Button, FormControl, FormControlLabel } from '@mui/material'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  getAgent,
  getBankdetails,
  getCustomerbooking,
  receiptCreate,
  receiptUpdate,
} from '../../../../services/configService'
import CustomTextArea from '../../../../components/common/CustomTextArea'
import { SnackbarContext } from '../../../../context/Snackbar'
import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'

import MultiAttachmentSend from '../../../../components/common/Multiattachment'

const Createreceipt = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [agent, setAgent] = React.useState([])
  const [bankData, setBankData] = React.useState([])
  const [agentId, setAgentId] = React.useState(null)
  const [customerId, setCustomerId] = React.useState(null)
  const [fieldError, setFieldError] = React.useState({})
  const [defaultAgent, setDefaultAgent] = React.useState(null)
  const [defaultCustomerId, setDefaultCustomerId] = React.useState(null)
  const [queryMessages, setQueryMessages] = React.useState(state?.remarks ?? '')
  const [bankID, setBankID] = React.useState(state?.bank_id ?? '')
  const [attachement, setAttachement] = React.useState('')
  const [bookings, setBookings] = React.useState([])
  const [preLogin, setPreLogin] = React.useState(false)
  const [selectedFiles, setSelectedFiles] = React.useState([])

  const [receiptdata, setReceiptdata] = React.useState({
    amount: state?.amount ?? 0,
    paymentmode: state?.payment_mode ?? 1,
    cheque_no: state?.cheque_no ?? '',
    cheque_date: dayjs(state?.cheque_date) ?? '',
    utr_no: state?.cheque_no ?? '',
    tds_amt: state?.tds_amt ?? '',
    Payment_Trans_ID: state?.cheque_no ?? '',
    cheque_return: state?.cheque_return ?? '',
    cheque_return_date: dayjs(state?.cheque_return_date) ?? '',
    cheque_bank_name: state?.cheque_bank_name ?? '',
    ded_amt: state?.ded_amt ?? '',
  })

  const handleChange = (e, v1, v2) => {
    const { name, value } = e.target
    setReceiptdata((prev) => ({
      ...prev,
      [name]: value,
    }))
  }



  const handleChangeAgent = (e, v) => {
    setAgentId(v?.id)
    const x = agent.find((a) => a.id === v?.id)
    setDefaultAgent(x)
    setFieldError((prev) => ({ ...prev, agent: '' }))
  }
  const handleChangeCustomer = (e, v) => {
    setCustomerId(v?.id)
    const x = bookings.find((a) => a.id === v?.id)
    setDefaultCustomerId(x)
    // setFieldError((prev) => ({ ...prev, agent: '' }))
  }
  // console.log('customerId', defaultCustomerId)

  const handlePreLogin = () => {
    setPreLogin(true)
  }
  const handleAgentback = () => {
    setPreLogin(false)
  }
  async function getAgentlist() {
    const get_agentlist = await getAgent()
    setAgent(get_agentlist?.data?.filter((item) => item.is_active === 1))
    
  }

  async function getBookingList() {
    try {
      const res = await getCustomerbooking()
      if (res !== 204 && res !== 401) {
        setBookings(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  

  async function getBankdata() {
    // const get_bank = await getBankdetails()

    try {
      const res = await getBankdetails()
      if (res !== 204 && res !== 401) {
        setBankData(res.data)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const handlebankDetails = (event) => {
    const bank_id = event.target.value
    setBankID(bank_id)
  }

  const handleDateChange = (date) => {
    setReceiptdata((prevDetails) => ({
      ...prevDetails,
      cheque_date: date.format('YYYY/MM/DD'),
    }))
  }

  const handlechequeRDateChange = (date) => {
    setReceiptdata((prevDetails) => ({
      ...prevDetails,
      cheque_return_date: date.format('YYYY/MM/DD'),
    }))
  }

  const generateMessage = async () => {
    const data = new FormData()
    data.append('customer_id', agentId || customerId)
    data.append('amount', receiptdata.amount)
    data.append('payment_mode', receiptdata.paymentmode)
    data.append(
      'cheque_no',
      receiptdata.paymentmode === 1
        ? receiptdata.cheque_no
        : receiptdata.paymentmode === 2
        ? receiptdata.utr_no
        : receiptdata.paymentmode === 4
        ? receiptdata.Payment_Trans_ID
        : null
    )
    selectedFiles.forEach((file, index) => {
      data.append(`files[${index}]`, file) // Append files using array-like keys
    })

    data.append(
      'cheque_date',
      dayjs(receiptdata.cheque_date).format('YYYY-MM-DD')
    )
    data.append('bank_id', bankID) // Bank ID from the bank API
    data.append('tds_amt', receiptdata.tds_amt)
    data.append('remarks', queryMessages)
    data.append('is_prelogin', preLogin ? 2 : 0)
    data.append(
      'cheque_bank_name',
      receiptdata.cheque_bank_name ? receiptdata.cheque_bank_name : null
    )
    data.append('ded_amt', receiptdata.ded_amt)
   

    try {
      // setIsLoading(true)
      const res = await receiptCreate(data)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Receipt Created Successfully')
        setOn(true)
        navigate('/receipt')
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
      console.log('error in generate quote ', error)
    }
  }
  const updateReceipt = async () => {
    const sendData = new FormData()
    sendData.append('customer_id', agentId || state.customer_id)
    sendData.append('amount', receiptdata.amount)
    sendData.append('payment_mode', receiptdata.paymentmode)

    // Conditionally add cheque_no, UTR No, or Payment_Trans_ID based on payment_mode
    if (receiptdata.paymentmode === 1) {
      sendData.append('cheque_no', receiptdata.cheque_no)
    } else if (receiptdata.paymentmode === 2) {
      sendData.append('utr_no', receiptdata.utr_no)
    } else if (receiptdata.paymentmode === 4) {
      sendData.append('Payment_Trans_ID', receiptdata.Payment_Trans_ID)
    }

    // Add formatted cheque_date
    sendData.append(
      'cheque_date',
      dayjs(receiptdata.cheque_date).format('YYYY-MM-DD')
    )

    // Add bank_id
    sendData.append('bank_id', bankID)

    // Add tds_amt
    sendData.append('tds_amt', receiptdata.tds_amt)

    // Add remarks
    sendData.append('remarks', queryMessages)

    // Add cheque_return
    sendData.append(
      'cheque_return',
      receiptdata.cheque_return ? receiptdata.cheque_return : 0
    )
    attachement && sendData.append('file', attachement ? attachement : '')

    // Add cheque_return_date, if available
    if (receiptdata.cheque_return_date) {
      sendData.append(
        'cheque_return_date',
        dayjs(receiptdata.cheque_return_date).format('YYYY-MM-DD')
      )
    }

    // Add cheque_bank_name, if available
    if (receiptdata.cheque_bank_name) {
      sendData.append('cheque_bank_name', receiptdata.cheque_bank_name)
    }

    try {
      // setIsLoading(true)
      const res = await receiptUpdate(state.id, sendData, null)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Receipt Updated Successfully')
        setOn(true)
        navigate('/receipt')
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
      console.log('error in generate quote ', error)
    }
  }

  React.useEffect(() => {
    getAgentlist()
    getBankdata()
    getBookingList()
  }, [])
  React.useEffect(() => {
    if (state?.is_prelogin === 2) {
      setPreLogin(true)
    }
  }, [state])

  React.useEffect(() => {
    if (state?.customer_id && agent.length > 0) {
      const selectedAgent = agent.find((a) => a.id === state.customer_id)
      setDefaultAgent(selectedAgent)
    }
  }, [state, agent])
  React.useEffect(() => {
    if (state?.customer_id && bookings.length > 0) {
      const selectedcustomer = bookings.find((a) => a.id === state.customer_id)
      setDefaultCustomerId(selectedcustomer)
    }
  }, [state?.customer_id, bookings])



  return (
    <DashboardLayout
      heading='Create Receipt'
      pathName='Receipt'
      button={true}
      // onClick={createReceipt}
      btnText={state ? 'Update' : 'Save'}
      btnIcon={<Save />}
      pathName1={'Create Receipt'}
      onClick={state ? updateReceipt : generateMessage}
      pathLink={'/receipt'}
    >
      <Card>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant='h6'
            sx={{ fontSize: '17px', fontWeight: 'bold' }}
          >
            Fill Details
          </Typography>
          {/* <Button
            sx={{ float: 'right' }}
            variant='outlined'
            startIcon={<Cached />}
            disabled={state ? true : false}
            onClick={preLogin ? handleAgentback : handlePreLogin}
          >
            {preLogin ? 'Back Agent (B2B)' : 'Pre-Login (D2C)'}
          </Button> */}
        </CardContent>
        <CardContent>
          <Grid container spacing={2}>
            {preLogin === false && (
              <Grid item md={4} xs={4}>
                <Autocomplete
                  fullWidth
                  className='form_style'
                  disablePortal
                  options={agent}
                  value={defaultAgent}
                  getOptionLabel={(option) =>
                    `${option.name} ${option.email_id}`
                  }
                  onChange={(e, v) => handleChangeAgent(e, v)}
                  renderOption={(props, option) => (
                    <Box
                      component='li'
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <Typography variant='subtitle2' color='initial'>
                        {option.name}
                      </Typography>{' '}
                      &nbsp;
                      <Typography variant='subtitle2' color='GrayText'>
                        {option.email_id}
                      </Typography>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Agent list'
                      error={fieldError?.agent}
                      helperText={fieldError?.agent && 'Please select Agent'}
                    />
                  )}
                  size='small'
                />
              </Grid>
            )}
            {preLogin === true && (
              <Grid item md={4} xs={4}>
                <Autocomplete
                  fullWidth
                  className='form_style'
                  disablePortal
                  options={bookings}
                  value={defaultCustomerId}
                  getOptionLabel={(option) =>
                    `${option.billing_name} ${option.billing_email}`
                  }
                  onChange={(e, v) => handleChangeCustomer(e, v)}
                  renderOption={(props, option) => (
                    <Box
                      component='li'
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <Typography variant='subtitle2' color='initial'>
                        #{option.id}:- {option.billing_name}
                      </Typography>{' '}
                      &nbsp;
                      <Typography variant='subtitle2' color='#3a87ad'>
                        ({option.billing_email}) Rs.{option.total_fare}
                      </Typography>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Customer list'
                      // error={fieldError?.agent}
                      // helperText={fieldError?.agent && 'Please select Agent'}
                    />
                  )}
                  size='small'
                />
              </Grid>
            )}

            <Grid item md={4} xs={4}>
              <TextField
                type='number'
                variant='outlined'
                className='form_style'
                label='Amount Paid'
                size='small'
                name='amount'
                value={receiptdata.amount}
                // error={error && error['amount']}
                onChange={(e) => handleChange(e, 'amount')}
                fullWidth
              />
            </Grid>
            <Grid item md={1} xs={4}></Grid>
            <Grid item md={4} xs={4}>
              <FormControl
                sx={{ marginLeft: '' }}
                // error={error && error["lead_status"]}
                fullWidth
              >
                <InputLabel id='demo-simple-select-label' size='small'>
                  Mode of Payment
                </InputLabel>
                <Select
                  className='form_style'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  size='small'
                  label='Mode of payment'
                  name='paymentmode'
                  value={receiptdata.paymentmode}
                  onChange={(e) => handleChange(e, 'paymentmode')}
                  // defaultValue={state?.lead_status}
                >
                  <MenuItem value={1}>CHEQUE</MenuItem>
                  <MenuItem value={2}>NEFT/RTGS/IMPS</MenuItem>
                  <MenuItem value={3}>CASH</MenuItem>
                  <MenuItem value={4}>ONLINE</MenuItem>
                </Select>
                {/* <FormHelperText>{error && error["lead_status"]}</FormHelperText> */}
              </FormControl>
            </Grid>

            <Grid item md={4} xs={4}>
              <TextField
                variant='outlined'
                className='form_style'
                label={
                  receiptdata.paymentmode === 1
                    ? 'Cheque No.'
                    : receiptdata.paymentmode === 2
                    ? 'UTR No.'
                    : receiptdata.paymentmode === 4
                    ? 'Payment Trans ID'
                    : ''
                }
                size='small'
                name={
                  receiptdata.paymentmode === 1
                    ? 'cheque_no'
                    : receiptdata.paymentmode === 2
                    ? 'utr_no'
                    : receiptdata.paymentmode === 4
                    ? 'Payment_Trans_ID'
                    : ''
                }
                value={
                  receiptdata.paymentmode === 1
                    ? receiptdata.cheque_no
                    : receiptdata.paymentmode === 2
                    ? receiptdata.utr_no
                    : receiptdata.paymentmode === 4
                    ? receiptdata.Payment_Trans_ID
                    : null
                }
                // error={error && error['cheque_no']}
                disabled={receiptdata.paymentmode === 3}
                onChange={(e) => {
                  if (receiptdata.paymentmode === 1) {
                    if (e.target.value.length < 7) {
                      handleChange(e, 'cheque_no')
                    }
                  } else if (receiptdata.paymentmode === 2) {
                    if (e.target.value.length < 17) {
                      handleChange(e, 'utr_no')
                    }
                  } else if (receiptdata.paymentmode === 4) {
                    handleChange(e, 'Payment_Trans_ID')
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className='form_style'
                  slotProps={{
                    textField: { size: 'small', fullWidth: 'true' },
                  }}
                  format='DD-MM-YYYY'
                  label='Cheque Date'
                  value={
                    receiptdata.cheque_date
                      ? dayjs(receiptdata.cheque_date)
                      : null
                  }
                  onChange={handleDateChange}
                  disablePast
                  disabled={
                    receiptdata.paymentmode === 2 ||
                    receiptdata.paymentmode === 3 ||
                    receiptdata.paymentmode === 4
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={4} xs={4}>
              <TextField
                type='text'
                variant='outlined'
                className='form_style'
                label='Cheque Bank Name'
                size='small'
                name='cheque_bank_name'
                value={receiptdata.cheque_bank_name}
                // error={error && error['cheque_no']}
                disabled={
                  receiptdata.paymentmode === 3 ||
                  receiptdata.paymentmode === 4 ||
                  receiptdata.paymentmode === 2
                }
                onChange={(e) => handleChange(e, 'cheque_bank_name')}
                fullWidth
              />
            </Grid>

            {/* {receiptdata.paymentmode === 2 && (
              <Grid item md={4} xs={4}>
                <TextField
                  variant='outlined'
                  className='form_style'
                  label='UTR  No.'
                  size='small'
                  name='utr_no'
                  value={receiptdata.utr_no}
                  // error={error && error['utr_no']}
                  onChange={(e) => handleChange(e, 'utr_no')}
                  fullWidth
                />
              </Grid>
            )}
            {receiptdata.paymentmode === 4 && (
              <Grid item md={4} xs={4}>
                <TextField
                  variant='outlined'
                  className='form_style'
                  label='Payment Trans ID.'
                  size='small'
                  name='Payment_Trans_ID'
                  value={receiptdata.Payment_Trans_ID}
                  // error={error && error['Payment_Trans_ID']}
                  onChange={(e) => handleChange(e, 'Payment_Trans_ID')}
                  fullWidth
                />
              </Grid>
            )} */}
            <Grid item md={4} xs={4}>
              <FormControl fullWidth size='small'>
                <InputLabel>Bank Name</InputLabel>
                <Select
                  label='Bank Name'
                  className='form_style'
                  value={bankID}
                  onChange={handlebankDetails}
                >
                  {bankData?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={4}>
              <TextField
                type='number'
                variant='outlined'
                className='form_style'
                label='TDS Amount'
                size='small'
                name='tds_amt'
                value={receiptdata.tds_amt}
                // error={error && error['tds_amt']}
                onChange={(e) => handleChange(e, 'tds_amt')}
                fullWidth
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <TextField
                type='number'
                variant='outlined'
                className='form_style'
                label='Dedution Amount'
                size='small'
                name='ded_amt'
                value={receiptdata.ded_amt}
                // error={error && error['tds_amt']}
                onChange={(e) => handleChange(e, 'ded_amt')}
                fullWidth
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <CustomTextArea
                placeholder={'Message...'}
                name={'remark'}
                value={queryMessages}
                onChange={(e) => setQueryMessages(e.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <MultiAttachmentSend
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </Grid>
            {state && (
              <>
                <Grid item md={2} xs={4}>
                  {/* <FormControl
                    sx={{ marginLeft: '' }}
                    // error={error && error["lead_status"]}
                    fullWidth
                  >
                    <InputLabel id='demo-simple-select-label' size='small'>
                      Check Return
                    </InputLabel>
                    <Select
                      className='form_style'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      size='small'
                      label='Mode of payment'
                      name='cheque_return'
                      value={receiptdata.cheque_return}
                      onChange={(e) => handleChange(e, 'cheque_return')}
                      // defaultValue={state?.lead_status}
                    >
                      <MenuItem value={0}>Not Returned</MenuItem>
                      <MenuItem value={1}>Returned</MenuItem>
                    </Select>
                    <FormHelperText>{error && error["lead_status"]}</FormHelperText>
                  </FormControl> */}
                  <FormControl
                    sx={{ marginLeft: '' }}
                    // error={error && error["cheque_return"]}
                    fullWidth
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={receiptdata.cheque_return === 1}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: 'cheque_return',
                                  value: e.target.checked ? 1 : 0,
                                },
                              },
                              'cheque_return'
                            )
                          }
                          name='cheque_return'
                        />
                      }
                      label='Check Return'
                    />
                    {/* <FormHelperText>{error && error["cheque_return"]}</FormHelperText> */}
                  </FormControl>
                </Grid>
                {receiptdata.cheque_return === 1 && (
                  <Grid item md={4} xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className='form_style'
                        slotProps={{
                          textField: { size: 'small', fullWidth: 'true' },
                        }}
                        format='DD-MM-YYYY'
                        label='Cheque Return Date'
                        value={
                          receiptdata.cheque_return_date
                            ? dayjs(receiptdata.cheque_return_date)
                            : null
                        }
                        onChange={handlechequeRDateChange}
                        disablePast
                      />
                    </LocalizationProvider>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  )
}

export default Createreceipt
